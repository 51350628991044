<template>
  <div class="setting-box">
    <nav-bar :title="$t('瀏覽歷史')" :isFixed="true" :goBack="goBack" :bgc="'background: #ffffff'" >
      <template  #right>
        <div @click="handleEdit">{{isEdit?$t('完成'):$t('编辑')}}</div>
      </template>
    </nav-bar>
    <div class="box-main">
      <historyItems v-for="(item,index) in list" :key="index" :objData="item" :index="index" :isEdit="isEdit" @curSelect="handleCurSelect" @rowSelectAll="handlerowAll" />
      <van-loading v-if="isMore" size="13px" class="loading" vertical>{{ $t("加载中") }}...</van-loading>
        <div v-else class="more">{{ $t("沒有更多啦") }}~</div>
    </div>
    <div :class="['footer',{'upAndDown':isEdit}]">
      <div class="left">
        <div class="radio">
          <img v-if="isSelectAll" @click="handleOnAllchecked(false)"  src="@/assets/icons/green_select.png">
          <img v-else @click="handleOnAllchecked(true)" src="@/assets/icons/none_select.png">
        </div>
        <div class="all">{{$t('全選')}}</div>
      </div>
      <div class="right">
        <div class="move" @click="handleMoveCollect">{{$t('移動我的收藏')}}</div>
        <div class="del" @click="handleDel">{{$t('删除')}}</div>
      </div>
    </div>
    <promptDialog ref="promptDialogRef" :confirmText="confirmText" :content="content" @success="handleSuccess" />
  </div>
</template> 
<script>
import { getBrowsingHistory ,batchCollectProduct,deleteBrowsingHistory } from "@/api/user"
import mixins from "@/utils/mixins.js"
import historyItems from "./components/history-item.vue"
import promptDialog from "@/components/promptDialog/index.vue"
import { mapState } from "vuex"
export default {
  name:"historyList",
  mixins: [mixins],
  components: {
    promptDialog,
    historyItems
  },
  data(){
    return{
      langEnv: 2,
      params:{
        pageIndex: 1,
        pageSize: 30
      },
      confirmText: this.$t('刪除'),
      confirmText1: this.$t('去認證'),
      content: this.$t('確認刪除？'),
      list:[],
      isEdit:false,
      isMore: false,
    }
  },
  computed: {
    isSelectAll () {
      if (this.list.length) {
        const blom = this.list.every(item =>item.products.every(it=>it.isRadio===true))
        return blom
      } else {
        return false
      }

    },
  },
  created(){
    this.langEnv = window.localStorage.getItem("langEnv") || 2
      this.init()
  },
  mounted () {
    window.addEventListener("scroll", this.handleScroll,true)
  },
  methods:{
    async handleMoveCollect(){
      const searchProductIds = this.list.reduce((prev,cur) => {
        const matched = cur.products
          .filter(item => item.isRadio)
          .map(it => (it.searchProductId));
        return prev.concat(matched);
      }, []);
      if(!searchProductIds.length){
        this.$toast(this.$t('至少選擇壹個商品'))
        return
      }
      const res = await batchCollectProduct({isCollected: true,searchProductIds})
       if (res.resultID === 1200) {
          this.$toast(this.$t('操作成功'))
          // 删除当前浏览历史
          this.handleSuccess()
        }

    },
    handleDel(){
      const blom = this.list.some(item =>item.products.some(it=>it.isRadio===true))
      if(!blom){
        this.$toast(this.$t('至少選擇壹個商品'))
        return
      }
      this.$refs.promptDialogRef.open()
    },
    handleOnAllchecked(data){
      this.list.forEach(item=>{
        item.products.forEach(it=>it.isRadio = data)
      })
    },
    handlerowAll(data){
      this.list[data.index]?.products.forEach(item=>{
        item.isRadio= data.isRow
      })
    },
    handleCurSelect(data){
      this.list[data.index].products[data.idx].isRadio=!this.list[data.index].products[data.idx].isRadio
    },
    handleEdit(){
      this.isEdit = !this.isEdit
      if(this.isEdit){
        this.handleOnAllchecked(false)
      }
    },
    async init () {
      this.params.pageIndex = 1
      await this.getData()
    },
    async getData () {
      const res = await getBrowsingHistory(this.params)
      if (res.resultID === 1200) {
        if (this.params.pageIndex > 1) {
          if (res.data.length === 0) {
            this.isMore = false
            window.removeEventListener('scroll', this.handleScroll, true)
          } else {
            const data =  res.data.map(item=>{
              return{
                ...item,
                products:item.products.map(it=>{
                  return{
                    ...it,
                    isRadio:false
                  }
                })
              }
            })
            this.list = [...this.list, ...data]
          }
        } else {
          this.list = res.data.map(item=>{
            return{
              ...item,
              products:item.products.map(it=>{
                return{
                  ...it,
                  isRadio:false
                }
              })
            }
          })
        }
          
      }

    },
    async handleScroll (event) {
      const windowHeight = window.innerHeight
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop
      const hasVerticalScrollbar =
        document.documentElement.scrollHeight >
        document.documentElement.clientHeight

      if (hasVerticalScrollbar && scrollTop + windowHeight >= documentHeight) {
        this.isMore = true
        this.params.pageIndex += 1
        await this.getData()
      }
    },
    async handleSuccess () {
      const ids = this.list.reduce((prev,cur) => {
        const matched = cur.products
          .filter(item => item.isRadio)
          .map(it => (it.productId));
        return prev.concat(matched);
      }, []);
      const res = await deleteBrowsingHistory({language:this.langEnv,ids}) 
      if (res.resultID === 1200) {
          this.$toast(this.$t('操作成功'))
          this.init()
          this.isEdit=false
      }
    },
    goBack () {
      this.$router.back()
    }
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
}
</script>
<style lang="scss" scoped>
.radio {
  width: 24px;
  height: 24px;
  font-size: 0;

}
img {
  width: 100%;
  height: 100%;
}
.setting-box{
  padding-top:44px;
  padding-bottom:56px;
  box-sizing: border-box;
  background:#FFFFFF;
  min-height: 100vh;
  position: relative;
  .box-main{
    margin:16px 8px 24px 16px;
    box-sizing: border-box;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
  }
  .footer{
    background:#FFFFFF;
    position:fixed;
    left:0;
    bottom:-100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 16px;
    width:100%;
    box-sizing: border-box;
    height:56px;
    transition: all 1s;
    &.upAndDown{
      transition: all 1s;
      bottom:0;
    }
    .left{
      display: flex;
      align-items: center;
    }
    .right{
      display: flex;
      align-items: center;
      .move{
        border-radius: 20px 20px 20px 20px;
        border: 1px solid rgba(0,0,0,0.26);
        font-weight: 500;
        font-size: 13px;
        color: rgba(0,0,0,0.9);
        padding:5px 16px;
        margin-right:8px;
      }
      .del{
        padding:5px 16px;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid #F6B6AE;
        font-weight: 500;
        font-size: 13px;
        color: #E84935;
      }
    }
  }
}
.more {
  text-align: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-bottom: 10px;
}

.loading {
  text-align: center;
  padding-bottom: 10px;
}
</style>