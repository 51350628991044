<template>
  <div class="goods">
      <div class="header">
          <div class="radio" v-if="isEdit" @click="handleSelectAll">
            <img v-if="isRow"  src="@/assets/icons/green_select.png">
            <img v-else src="@/assets/icons/none_select.png">
          </div>
          <div class="time">{{goods.date}}</div>
      </div>
      <div class="goods-box">
        <div class="goods-item" v-for="(item,idx) in goods.products" :key="idx" @click="goproductDetails(item)">
          <div class="goods-img">
            <img :src="`${getProductImageUrl(item.picturePath,400)}`" alt="" @error="handleImageError" />
            <div v-if="isEdit" class="radio abs" @click="handleSelect(idx)">
              <img v-if="item.isRadio" src="@/assets/icons/green_select.png">
              <img v-else  src="@/assets/icons/none_select.png">
            </div>
          </div>
          <div class="name">{{item.name}}</div>
          <div class="price">{{formatNumber(item.price)}}</div>
        </div>
      </div>
  </div>
</template>
<script>
import Mixins from "@/utils/mixins"
export default {
  mixins: [Mixins],
  name:"hostory-item",
  props: {
    objData: {
      type: Object,
      default: {}
    },
    index: {
      type: Number,
      default: 0
    },
    isEdit: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    objData: {
      handler (val) {
        this.goods = val
        this.isRow = val.products.every(item=>item.isRadio)
      },
      deep: true,
      immediate: true
    }
  },
  data(){
    return{
      goods:{},
      isRow:false
    }
  },
  methods: {
    handleSelectAll(){
      this.$emit('rowSelectAll',{index:this.index,isRow:!this.isRow})
    },
    handleSelect(idx){
      this.$emit('curSelect',{index:this.index,idx})
    },
    // 跳转详情
    goproductDetails (data) {
      if(this.isEdit) return
      this.$router.push(`/product/${data.searchProductId}`)

    },
  }
}
</script>
<style lang="scss" scoped>
.radio {
  width: 24px;
  height: 24px;
  font-size: 0;

}
img {
  width: 100%;
  height: 100%;
}
.header{
  display: flex;
  align-items: center;
  .time{
    margin-left:4px;
    font-weight: bold;
    font-size: 17px;
    color: #1A1A1A;
    line-height: 24px;
  }
}
.goods-box{
  margin-top:16px;
  display: flex;
  align-items: center;
  flex-wrap:wrap;
  .goods-item{
    width:109px;
    margin-bottom:20px;
    margin-right:8px;
    .goods-img{
      width:109px;
      height:109px;
      position: relative;
      .abs{
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
    .name{
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top:4px;
      font-weight: 500;
      font-size: 13px;
      color: #666666;
      line-height: 20px;
    }
    .price{
      margin-top:4px;
      font-weight: 800;
      font-size: 11px;
      color: rgba(0,0,0,0.9);
      line-height: 16px;
    }
  }
}
</style>